/* eslint-disable */

import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "../components/AEmpresa/Slider"

import PreviewVideoImg from "../components/AEmpresa/PreviewVideoImg"
//import MatheusRibeiroImg from "../components/AEmpresa/MatheusRibeiroImg"

const AEmpresaPage = ({ location }) => (
  <Layout darkmode={true} location={location}>
    <SEO title="Portfólio" />
    <Container>
      <SectionOne>
        <div
          className="gatsby-image-wrapper on-hover"
          onClick={() =>
            window.open("https://www.youtube.com/watch?v=BM4xwxcb2zE")
          }
        >
          <PreviewVideoImg />
        </div>
      </SectionOne>

      <SectionTwo>
        <SectionTwoWrapper>
          <h1>a zoombe</h1>
          <p>
            Se todo mundo pudesse te ver e te ouvir, o que você diria? A Zoombe
            nasceu para ajudá-lo a responder essa questão. Acreditamos no poder
            do audiovisual para a geração de valor às marcas. Produzimos
            conteúdo para as mais diversas plataformas, com o foco sempre
            voltado para o entendimento de uma mensagem relevante ao seu
            negócio.
          </p>
          <p>
            Com um time jovem e ligado nas últimas tendências do universo
            digital, gostamos de criar peças que impactem o público e gerem
            resultados efetivos. Um dos nossos diferenciais é um estúdio
            totalmente equipado e personalizável às demandas dos nossos
            clientes, preparado para gravações e transmissões ao vivo.
          </p>
        </SectionTwoWrapper>
      </SectionTwo>

      <SectionThree>
        <Slider bgColor="#212121" fullScreen={true} />
        {/* <SectionThreeWrapper>
          <SectionThreeWrapperLeft>
            <h2>o time</h2>
            <h1>matheus ribeiro</h1>
            <p>
              A internet virou a fonte mais dinâmica e fácil para assistir a
              vídeos. Dessa forma, fazemos a produção completa de vídeos para a
              web com roteiro, duração, maneiras de divulgar o vídeo e que tipo
              de resultados devem ser esperados.
            </p>
          </SectionThreeWrapperLeft>
          <SectionThreeWrapperRight>
            <MatheusRibeiroImg />
            <p>Matheus</p>
          </SectionThreeWrapperRight>
        </SectionThreeWrapper> */}
      </SectionThree>
    </Container>
  </Layout>
)

const Container = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #212121;
`

const SectionOne = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  height: fit-content;

  @media (max-width: 1250px) {
    padding-right: 30px;
    padding-left: 20px;
  }

  @media (max-width: 499px) {
    padding-right: 25px;
    padding-left: 20px;
  }
`

const SectionTwo = styled.section`
  max-width: 1200px;
  margin: 50px auto;
  height: fit-content;

  h1,
  p {
    color: white;
    max-width: 657px;
  }

  h1,
  h2,
  p {
    font-weight: 400;
  }

  h1 {
    font-size: 50px;
    margin-bottom: 16px;
  }
  p + p {
    margin-top: 20px;
  }

  @media (max-width: 499px) {
    padding-right: 7px;
    padding-left: 0px;
  }
`

const SectionTwoWrapper = styled.div`
  margin: 0 auto;
  max-width: 780px;

  @media (max-width: 850px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const SectionThree = styled.section`
  max-width: 780px;
  margin: 100px auto;
  height: fit-content;

  h1,
  p {
    color: white;
    max-width: 657px;
  }

  h1 {
    margin-bottom: 16px;
  }
  p + p {
    margin-top: 20px;
  }

  @media (max-width: 499px) {
    margin: 20px auto;
  }
`

const SectionThreeWrapper = styled.div`
  margin: 0 auto;
  max-width: 1000px;

  display: flex;
  justify-content: center;

  @media (max-width: 850px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const SectionThreeWrapperLeft = styled.div`
  h2 {
    color: red;
    font-size: 40px;
  }
  h1 {
    font-weight: bold;
    font-size: 50px;
  }
  h3 {
    font-size: 28px;
  }
  p {
    font-size: 18px;
  }

  max-width: 400px;
  padding-right: 20px;
  margin-right: 40px;

  @media (max-width: 850px) {
    h1 {
      font-size: 40px;
    }
  }

  @media (max-width: 700px) {
    p {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 499px) {
    p {
      font-size: 16px;
      max-width: 100%;
    }

    h1,
    h2 {
      font-size: 30px;
    }

    margin-right: 0px;
    padding-right: 0px;
  }
`

const SectionThreeWrapperRight = styled.div`
  width: 340px;
  p {
    display: none;
  }

  @media (max-width: 499px) {
    width: 100%;
  }
`

export default AEmpresaPage
