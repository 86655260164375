import React from "react"
import PropTypes from "prop-types"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import MatheusRibeiroImg from "../AEmpresa/MatheusRibeiroImg"
import AlineImg from "../AEmpresa/AlineImg"
import Andreia from "../AEmpresa/Andreia"
import MatheusLopesImg from "../AEmpresa/MatheusLopesImg"
import GabrielImg from "../AEmpresa/GabrielImg"
import AlexImg from "../AEmpresa/AlexImg"
import AntonioImg from "../AEmpresa/AntonioImg"

import styled from "styled-components"

export default function Slider({ bgColor, fullScreen }) {
  return (
    <Container fullScreen={fullScreen} bgColor={bgColor}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        emulateTouch
        centerMode={false}
        autoPlay={false}
        showArrows={false}
        showIndicators={true}
      >
        <ContentWrapper>
          <ContentWrapperLeft>
            <h2>o time</h2>
            <h1>matheus ribeiro</h1>
            <p className="titulo">diretor-geral</p>
            <p>
              Jornalista graduado pela Universidade Federal de Goiás.
              Especialista em Marketing e Gestão. Vasta experiência em
              audiovisual e telejornalismo.
            </p>
          </ContentWrapperLeft>
          <ContentWrapperRight>
            <MatheusRibeiroImg />
          </ContentWrapperRight>
        </ContentWrapper>

        <ContentWrapper>
          <ContentWrapperLeft>
            <h2>o time</h2>
            <h1>aline bezerra</h1>
            <p className="titulo">gerente de planejamento</p>
            <p>
              Jornalista graduada pela PUC Goiás. Experiência em gestão de
              equipes. Responsável pelo atendimento ao cliente e organização dos
              jobs.
            </p>
          </ContentWrapperLeft>
          <ContentWrapperRight>
            <AlineImg />
          </ContentWrapperRight>
        </ContentWrapper>

        <ContentWrapper>
          <ContentWrapperLeft>
            <h2>o time</h2>
            <h1>antônio cavalcante</h1>
            <p className="titulo">diretor de criação</p>
            <p>
              Graduado em Design Gráfico. Experiente em branding, animação
              visual e elaboração de vídeos.
            </p>
          </ContentWrapperLeft>
          <ContentWrapperRight>
            <AntonioImg />
          </ContentWrapperRight>
        </ContentWrapper>

        <ContentWrapper>
          <ContentWrapperLeft>
            <h2>o time</h2>
            <h1>alex almeida</h1>
            <p>videographer</p>
            <p>
              Graduado em Marketing pela UNOPAR e Gestão Comercial pela UNESC.
              Tem passagem por emissoras de rádio e TV.
            </p>
          </ContentWrapperLeft>
          <ContentWrapperRight>
            <AlexImg />
          </ContentWrapperRight>
        </ContentWrapper>

        <ContentWrapper>
          <ContentWrapperLeft>
            <h2>o time</h2>
            <h1>gabriel gomes</h1>
            <p className="titulo">motion designer</p>
            <p>
              Graduando em Design Gráfico pela PUC Goiás. Vasto conhecimento em
              pacote Adobe e Autodesk.
            </p>
          </ContentWrapperLeft>
          <ContentWrapperRight>
            <GabrielImg />
          </ContentWrapperRight>
        </ContentWrapper>

        <ContentWrapper>
          <ContentWrapperLeft>
            <h2>o time</h2>
            <h1>matheus lopes</h1>
            <p className="titulo">produtor</p>
            <p>
              Jornalista graduado pela UNIALFA. Atuação focada em gestão de
              redes sociais e produção audiovisual.
            </p>
          </ContentWrapperLeft>
          <ContentWrapperRight>
            <MatheusLopesImg />
          </ContentWrapperRight>
        </ContentWrapper>

        <ContentWrapper>
          <ContentWrapperLeft>
            <h2>o time</h2>
            <h1>andréa castro</h1>
            <p className="titulo">administrativo</p>
            <p>
              Graduada em Administração pela FANAP. Especialista em Gestão
              Contábil e Logística.
            </p>
          </ContentWrapperLeft>
          <ContentWrapperRight>
            <Andreia />
          </ContentWrapperRight>
        </ContentWrapper>
      </Carousel>
    </Container>
  )
}

Slider.propTypes = {
  fullScreen: PropTypes.bool,
  bgColor: PropTypes.string,
}

Slider.defaultProps = {
  fullScreen: true,
  bgColor: "",
}

const Container = styled.div`
  width: 100%;
  height: fit-content;

  .carousel .slide {
    background: #212121;
  }
  .control-dots {
    position: relative;
    left: -251px;
    top: -40px;
  }

  .carousel .control-dots .dot {
    width: 26px;
    height: 26px;
  }

  @media (max-width: 835px) {
    .control-dots {
      display: flex;
      padding-left: 13px;
      align-self: flex-start;
      right: 0px;
      left: 0px;
      top: 0px;
    }
  }
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1000px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 835px) {
    padding-left: 20px;
    flex-direction: column;
  }
`

const ContentWrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h2 {
    color: #f44336;
  }

  p.titulo {
    text-transform: uppercase;
  }

  @media (max-width: 835px) {
    p {
      max-width: 390px;
    }
  }

  padding-right: 20px;
  text-align: left;
`

const ContentWrapperRight = styled.div`
  width: 100%;
  max-width: 50%;

  @media (max-width: 835px) {
    padding-top: 24px;
    max-width: 100%;
    padding-right: 20px;
  }
`
